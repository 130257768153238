<template>
    <section class="flex flex-col gap-4 my-account">
        <MyAccountLegend :help-link="helpLink">
            {{ $t("MyAccount.APIKey") }}
        </MyAccountLegend>

        <div v-if="apiRecord" class="flex flex-col gap-4 md:w-1/2">
            <div class="flex flex-col gap-2">
                <label for="companyID" class="font-bold">{{ $t("MyAccount.CompanyID") }}</label>
                <ButtonGroup>
                    <input
                        id="companyID"
                        :value="apiRecord.CompanyID"
                        class="w-full bg-gray-50 rounded-l"
                        type="text"
                        readonly />
                    <Button variant="default" @click.prevent="copyText(apiRecord.CompanyID)">
                        <i class="fas fa-clipboard" />
                    </Button>
                </ButtonGroup>
            </div>

            <div class="flex flex-col gap-2">
                <label for="apiKey" class="font-bold flex gap-2">
                    <span>{{ $t("MyAccount.APIKey") }}</span>
                    <Badge fill="light" :variant="statusBadgeVariant">
                        {{ apiRecord.APIKeyStatus }}
                    </Badge>
                </label>
                <ButtonGroup>
                    <input
                        id="apiKey"
                        :value="apiRecord.APIKey"
                        class="w-full bg-gray-50 rounded-l"
                        type="text"
                        readonly />
                    <Button variant="default" @click.prevent="copyText(apiRecord.APIKey)">
                        <i class="fas fa-clipboard" />
                    </Button>
                </ButtonGroup>
            </div>

            <div v-if="apiRecord.APIKeyTrialExpiration" class="flex flex-col gap-2">
                <label class="font-bold">{{ $t("MyAccount.TrialExpiration") }}</label>
                <span>{{ trialExpirationDate }}</span>
            </div>

            <div v-if="apiRecord.DSSToken" class="flex flex-col gap-2">
                <label for="dssToken" class="font-bold">{{ $t("MyAccount.DSSToken") }}</label>
                <ButtonGroup>
                    <input
                        id="dssToken"
                        :value="apiRecord.DSSToken"
                        class="w-full bg-gray-50 rounded-l"
                        type="text"
                        readonly />
                    <Button variant="default" @click.prevent="copyText(apiRecord.DSSToken)">
                        <i class="fas fa-clipboard" />
                    </Button>
                </ButtonGroup>
            </div>
        </div>
    </section>
</template>
<script setup lang="ts">
import { useClipboard } from "@vueuse/core";
import type { BadgeVariants } from "~/components/ui";

const helpLink = PageHelpers.GetDocsUrl("trustedparts-api/credentials/");

const { data: apiRecord } = await useFetchLocaleApi<APICustomer>("api/account/api-customer");
const { copy, copied } = useClipboard();
const { t } = useI18n();
const { toast } = useToast();

enum ApiStatus {
    Active = "Active",
    Requested = "Requested",
    Trial = "Trial",
    Suspended = "Suspended",
    SuspendedTrial = "Suspended Trial",
    Revoked = "Revoked",
    Default = "Default",
}

const trialExpirationDate = computed(() =>
    apiRecord.value?.APIKeyTrialExpiration ? new Date(apiRecord.value.APIKeyTrialExpiration).toDateString() : ""
);

const APIKeyStatus = apiRecord.value?.APIKeyStatus || ApiStatus.Default;

type ApiStatusMap = Record<ApiStatus, BadgeVariants["variant"]>;

const apiStatusMap: ApiStatusMap = {
    [ApiStatus.Active]: "success",
    [ApiStatus.Requested]: "warning",
    [ApiStatus.Trial]: "primary",
    [ApiStatus.Suspended]: "danger",
    [ApiStatus.SuspendedTrial]: "danger",
    [ApiStatus.Revoked]: "danger",
    [ApiStatus.Default]: "default",
};

const statusBadgeVariant = computed(() => apiStatusMap[APIKeyStatus as keyof ApiStatusMap]);

async function copyText(val: string) {
    await copy(val);

    if (copied.value) {
        toast({ title: t("Global.Copied"), variant: "success" });
    }
}
</script>
